<template>
  <div class="row">
    
    <div v-userRole="'Applicant,Parent,Student'" v-can="'access_applications'" class="col-xl-3 col-xxl-3 col-sm-6">
      <router-link :to="{name: 'Applications'}">
        <div class="widget-stat card bg-white">
          <div class="card-body">
            <div class="media">
              <span class="mr-3">
                <i class="la la-file"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Applications</h3>
                <small>Submit and display your applications</small>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div v-userRole="'Parent'" class="col-xl-3 col-xxl-3 col-sm-6">
      <router-link :to="{name: 'Student-Payments-Childrens-List'}">
        <div class="widget-stat card bg-white">
          <div class="card-body">
            <div class="media">
              <span class="mr-3">
                <i class="la la-dollar"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Payments</h3>
                <small>Mange your payments</small>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

  </div>
</template>